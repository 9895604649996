import { createMuiTheme } from "@material-ui/core/styles";
  
let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#556cd6",
      //light: '#757ce8',
      //main: '#3f50b5',
      //dark: '#002884',
      //contrastText: '#fff',
    },
    secondary: {
      main: "#19857b",
      //light: '#ff7961',
      //main: '#f44336',
      //dark: '#ba000d',
      //contrastText: '#000',
    },
    // error: {
    //   main: red.A400,
    // },
    background: {
      default: "#fff",
    },
  },
  // typography: {
  //   h5: {
  //     fontWeight: 500,
  //     fontSize: 26,
  //     letterSpacing: 0.5,
  //   },
  // },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  }, 

});


theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      root:{
        //backgroundColor: "#18202c",
      },
      paper: {
        backgroundColor: "#18202c",
      },
      //backgroundColor: "#18202c",
    },
    MuiStepIcon: {
      root: {
        color: '#b3b6b7',
        '&$completed': {
          color: '#33658a',
        },
        '&$active': {
          color: '#33658a',
        },
      },
      active: {},
      completed: {}, 
    },
    MuiTabs: {
      root: {
        [theme.breakpoints.up("md")]: {
          marginLeft: theme.spacing(1),
        },
      },
      indicator: {
        height: 4,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        //background: 'green',
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("sm")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    // MuiIconButton: {
    //   root: {
    //     padding: theme.spacing(1),
    //   },
    // },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#babcc0",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    // MuiAvatar: {
    //   root: {
    //     width: 32,
    //     height: 32,
    //   },
    // },
  },
};


export default theme;
 