import Firebase from '../firebase/clientApp';

//Crud
//Crear
export async function createData(collection, id, data){
    const Db = Firebase.firestore();
    return await Db.collection(collection).doc(id).set(data);
}
//Actualizar
export async function updateData(collection, id, data){ 
    const Db = Firebase.firestore();
    return await Db.collection(collection).doc(id).update(data);
}

//Actualizar
export async function setData(collection, id, data){
    data.timestamp = Firebase.firestore.FieldValue.serverTimestamp()
    const Db = Firebase.firestore();
    return await Db.collection(collection).doc(id).set(data);
}
 
//Obtener
export async function getData(collection, id){
    const Db = Firebase.firestore();
    return await Db.collection(collection).doc(id).get();
}
//Borrar
export async function deleteData(collection, id){
    const Db = Firebase.firestore();
    return await Db.collection(collection).doc(id).delete();
}


//Actualizar listas de arreglo
export async function updateArrayData(collection, id, data){
    const Db = await Firebase.firestore();
    const dataRef = await Db.collection(collection).doc(id);
    // Atomically add a new region to the "regions" array field.
    return await dataRef.update({ tokens: Firebase.firestore.FieldValue.arrayUnion(data) }) 
}

//Remover items de listas de arreglo
export async function removeArrayData(collection, id, data){
    const Db = await Firebase.firestore();
    const dataRef = await Db.collection(collection).doc(id);
    // Atomically remove a region from the "regions" array field.
    return await dataRef.update({ tokens: Firebase.firestore.FieldValue.arrayRemove(data) }) 
}

 