import React, { useEffect } from 'react';
import PropTypes from 'prop-types'; 
import Head from 'next/head';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from "@material-ui/core/styles"; 
//import { UserProvider } from '../context/userContext';
//import { DashboardProvider } from '../context/dashboardContext';
//IMPORT LOGROCKET
import '../LogRocket'
//Import perision to get notifications
import { askForPermissionNotification } from '../lib/webPush';
//STYLES
import "../styles/globals.css";
import theme from '../theme';

export default function MyApp({ Component, pageProps }) {
  
  useEffect(() => { 
    askForPermissionNotification();
    if("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
       navigator.serviceWorker.register("/firebase-messaging-sw.js").then(
          async function (registration) {
            console.log("Service Worker registration successful with scope: ", registration.scope);
          },
          function (err) {
            console.log("Service Worker registration failed: ", err);
          }
        );
      });
    }

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);  
  
  return (
    <>
     <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"/> 
        <link rel="manifest" href="/manifest.json" /> 
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" /> 
        <meta name='description' content='CIDU S.A.S. Empresa colombiana dedicada al desarrollo de proyectos catastrales e inmobiliarios para el sector de personas Naturales y Bancos' />
        <link href="/images/manifest/circle16.png" rel="icon" type="image/png" sizes="16x16"/>
        <link href="/images/manifest/circle32.png" rel="icon" type="image/png" sizes="32x32"/>
        <link rel="apple-touch-icon" href="/images/manifest/apple-touch-icon.png"/><meta name="theme-color" content="#33658a" />
      </Head>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        {/* <UserProvider> */}
          {/* <DashboardProvider> */}
            <CssBaseline />
            <Component {...pageProps} />
          {/* </DashboardProvider> */}
        {/* </UserProvider> */}
      </SnackbarProvider>
    </ThemeProvider>
    </>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};
