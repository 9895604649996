import Firebase from "../firebase/clientApp";  
import { updateArrayData } from '../fetchData/dataStore';

export const deleteTokenMessagin = async () => {
  return localStorage.removeItem('fcm_token')
}

export const setTokenMessagin = async (value) => {
  return localStorage.setItem('fcm_token', value)
}

export const getTokenMessagin = async () => {
  return localStorage.getItem('fcm_token')
}
 
export const askForPermissionNotification = async () => {
  try {
    if ((await getTokenMessagin()) !== null) {
      //console.log('fcm_token', localStorage.getItem('fcm_token')) 
      return false
    }
    const messaging = Firebase.messaging();
    const token = await messaging.getToken({ vapidKey: process.env.FIREBASE_PUBLIC_VAPID_KEY});
    await updateArrayData('userNotifications', 'webApp', token);
    await setTokenMessagin(token);
    //console.log('fcm_token', token); 
    
  } catch (e) {
    console.log("Error from messagin permission", e)
  }
};
